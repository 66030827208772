import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Contact = () => {
    const [general, setGeneral] = useState({});
    const [social, setSocial] = useState([]);
    useEffect(() => {
        fetch("/api/general").then((res) => res.json()).then((data) => {
            setGeneral(data);
        });
        fetch("/api/social").then((res) => res.json()).then((data) => {
            setSocial(data);
        });
    }, []);

    return (
        <>
            <section className="page-header">
                <div className="page-header-bg" style={{ backgroundImage: 'url(../uploads/page-header-bg.jpg)' }}>
                </div>
                <div className="container">
                    <div className="page-header__inner">
                        <ul className="thm-breadcrumb list-unstyled">
                            <li><Link to="/">الرئیسیة</Link></li>
                            <li><span>/</span></li>
                            <li>اتصال بنا</li>
                        </ul>
                        <h2>اتصال بنا</h2>
                    </div>
                </div>
            </section>
            <section className="google-map">
                <div className="container">
                    <div className="google-map-box">
                        <iframe className="google-map__one" allowFullScreen src="https://www.google.com/maps/embed/v1/place?q=erbil&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8" />
                    </div>
                    <div className="contact-details">
                        <div className="row">
                            <div className="col-xl-3 col-lg-6 col-md-6">
                                <div className="contact-details__single">
                                    <div className="contact-details__icon">
                                        <span className="icon-help" />
                                    </div>
                                    <div className="contact-details__text">
                                        <p>لديك سؤال؟</p>
                                        <h3><a href={`tel:00964${general.phone_no}`}>{general.phone_no}</a></h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-md-6">
                                <div className="contact-details__single">
                                    <div className="contact-details__icon">
                                        <span className="icon-mailbox" />
                                    </div>
                                    <div className="contact-details__text">
                                        <p>اكتب بريدا الكترونيا</p>
                                        <h3><a href={`mailto:${general.phone_no}`}>{general.email}</a></h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-md-6">
                                <div className="contact-details__single contact-details__single-last">
                                    <div className="contact-details__icon">
                                        <span className="icon-maps-and-flags" />
                                    </div>
                                    <div className="contact-details__text">
                                        <p>زيارة المكتب</p>
                                        <h3>{general.address}</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-md-6">
                                <div className="contact-details__single">
                                    <div className="contact-details__social">
                                        {social && social.map((item, index) => (
                                            <a key={index} href={item.social_link}>
                                                <div dangerouslySetInnerHTML={{ __html: item.social_icon }}></div>
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}

export default Contact;