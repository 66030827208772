import { Link } from "react-router-dom";
import $ from 'jquery';
const Header = ({ data, social }) => {
    $(window).on('scroll', function () {
        if ($(".stricked-menu").length) {
            var headerScrollPos = 130;
            var stricky = $(".stricked-menu");
            if ($(window).scrollTop() > headerScrollPos) {
                stricky.addClass("stricky-fixed");
            } else if ($(this).scrollTop() <= headerScrollPos) {
                stricky.removeClass("stricky-fixed");
            }
        }
        if ($(".scroll-to-top").length) {
            var strickyScrollPos = 100;
            if ($(window).scrollTop() > strickyScrollPos) {
                $(".scroll-to-top").fadeIn(500);
            } else if ($(this).scrollTop() <= strickyScrollPos) {
                $(".scroll-to-top").fadeOut(500);
            }
        }

    });

    function toggleNav() {
        $('.mobile-nav__wrapper').toggleClass('expanded');
    }

    return (
        <>
            <header className="main-header">
                <div className="main-header__wrapper">
                    <div className="main-header__wrapper-inner">
                        <div className="main-header__logo">
                            <Link to="/" className="fw-bold h1">
                                <span className="text-success"> شركة خیرات </span><span className="text-warning"> العراق</span>
                            </Link>
                        </div>
                        <div className="main-header__menu-box">
                            <div className="main-header__menu-box-top">
                                <ul className="list-unstyled main-header__contact-list">
                                    <li>
                                        <div className="icon">
                                            <i className="icon-email" />
                                        </div>
                                        <div className="text">
                                            <p>
                                                <a href={`mailto:${data.email}`}>{data.email}</a>
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <i className="icon-pin" />
                                        </div>
                                        <div className="text">
                                            <p> {data.address}</p>
                                        </div>
                                    </li>
                                </ul>
                                <div className="main-header__social">
                                    {social && social.map((item, index) => (
                                        <a key={index} href={item.social_link}>
                                            <div dangerouslySetInnerHTML={{ __html: item.social_icon }}></div>
                                        </a>
                                    ))}
                                </div>
                            </div>
                            <div className="main-header__menu-box-bottom">
                                <nav className="main-menu">
                                    <div className="main-menu__wrapper">
                                        <div className="main-menu__wrapper-inner">
                                            <div className="main-menu__left">
                                                <div className="main-menu__main-menu-box">
                                                    <span type="button" className="mobile-nav__toggler" onClick={toggleNav} >
                                                        <i className="fa fa-bars" />
                                                    </span>
                                                    <ul className="main-menu__list one-page-scroll-menu">
                                                        <li className="scrollToLink">
                                                            <Link to="/">الرئیسیة</Link>
                                                        </li>
                                                        <li className="scrollToLink">
                                                            <Link to="/products">منتجات</Link>
                                                        </li>
                                                        <li className="scrollToLink">
                                                            <a href="#why-us">لماذا نحن؟</a>
                                                        </li>
                                                        <li className="scrollToLink">
                                                            <a href="#our-vision">روئيتنا</a>
                                                        </li>
                                                        <li className="scrollToLink">
                                                            <a href="#about">عن شرکة</a>
                                                        </li>
                                                        <li className="scrollToLink">
                                                            <Link to="/contact-us">اتصال بنا</Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="main-menu__right">
                                                <div className="main-menu__search-cart-btn-box">
                                                    <div className="main-menu__btn-box">
                                                        <Link to="/contact-us" className="thm-btn main-menu__btn">
                                                            مزید من معلومات
                                                            <i className="icon-left-arrow" />
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </nav>
                            </div>
                        </div>
                        <div className="main-header__phone-contact-box">
                            <div className="main-header__phone-number">
                                <a href={`tel:964${data.phone_no}`}>
                                    <div dangerouslySetInnerHTML={{ __html: data.phone_no }}></div>
                                </a>
                            </div>
                            <div className="main-header__call-box">
                                <div className="main-header__call-inner">
                                    <div className="main-header__call-icon">
                                        <span className="fas fa-phone" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div className="stricky-header stricked-menu main-menu">
                <div className="sticky-header__content" >
                    <div className="main-menu__wrapper-inner">
                        <div className="main-menu__left">
                            <div className="main-menu__search-cart-btn-box">
                                <Link to="/" className="fw-bold h1">
                                    <span className="text-success"> شركة خیرات </span><span className="text-warning"> العراق</span>
                                </Link>
                            </div>
                        </div>
                        <div className="main-menu__right">
                            <div className="main-menu__main-menu-box">
                                <span type="button" className="mobile-nav__toggler" onClick={toggleNav}><i className="fa fa-bars" /></span>
                                <ul className="main-menu__list">
                                    <li className="scrollToLink">
                                        <Link to="/">الرئیسیة</Link>
                                    </li>
                                    <li className="scrollToLink">
                                        <Link to="/products">منتجات</Link>
                                    </li>
                                    <li className="scrollToLink">
                                        <a href="#why-us">لماذا نحن؟</a>
                                    </li>
                                    <li className="scrollToLink">
                                        <a href="#our-vision">روئيتنا</a>
                                    </li>
                                    <li className="scrollToLink">
                                        <a href="#about">عن شرکة</a>
                                    </li>
                                    <li className="scrollToLink">
                                        <Link to="/contact-us">اتصال بنا</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className="mobile-nav__wrapper">
                <div className="mobile-nav__overlay mobile-nav__toggler" />
                <div className="mobile-nav__content">
                    <span className="mobile-nav__close mobile-nav__toggler" onClick={toggleNav}><i className="fa fa-times" /></span>
                    <div className="logo-box">
                        <Link to="/" className="fw-bold h1">
                            <span className="text-success"> شركة خیرات </span><span className="text-warning"> العراق</span>
                        </Link>
                    </div>
                    <div className="mobile-nav__container" />
                    <ul className="main-menu__list">
                        <li onClick={toggleNav}>
                            <Link to="/">الرئیسیة</Link>
                        </li>
                        <li onClick={toggleNav}>
                            <Link to="/products">منتجات</Link>
                        </li>
                        <li onClick={toggleNav}>
                            <a href="#why-us">لماذا نحن؟</a>
                        </li>
                        <li onClick={toggleNav}>
                            <a href="#our-vision">روئيتنا</a>
                        </li>
                        <li onClick={toggleNav}>
                            <a href="#about">عن شرکة</a>
                        </li>
                        <li onClick={toggleNav}>
                            <Link to="/contact-us">اتصال بنا</Link>
                        </li>
                    </ul>
                    <ul className="mobile-nav__contact list-unstyled">
                        <li>
                            <i className="fa fa-envelope" />
                            <a href={`mailto:${data.email}`}>{data.email}</a>
                        </li>
                        <li>
                            <i className="fa fa-phone-alt" />
                            <a href={`tel:964${data.phone_no}`}>
                                <div dangerouslySetInnerHTML={{ __html: data.phone_no }}></div>
                            </a>
                        </li>
                    </ul>
                    <div className="mobile-nav__top">
                        <div className="mobile-nav__social">
                            {social && social.map((item, index) => (
                                <a key={index} href={item.social_link}>
                                    <div dangerouslySetInnerHTML={{ __html: item.social_icon }}></div>
                                </a>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Header;