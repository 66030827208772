import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

const Details = () => {
    const id = useParams().id;
    const [product, setProduct] = useState({});
    const [products, setProducts] = useState([]);
    const [social, setSocial] = useState([]);

    useEffect(() => {
        fetch(`/api/details/${id}`).then(res => res.json()).then(data => setProduct(data));
        fetch('/api/limit-products').then(res => res.json()).then(data => setProducts(data));
        fetch('/api/social').then(res => res.json()).then(data => setSocial(data))
    }, [id]);

    return (
        <>
            <section className="page-header">
                <div className="page-header-bg" style={{ backgroundImage: 'url(../uploads/page-header-bg.jpg)' }}>
                </div>
                <div className="container">
                    <div className="page-header__inner">
                        <ul className="thm-breadcrumb list-unstyled">
                            <li><Link to="/">الرئیسیة</Link></li>
                            <li><span>/</span></li>
                            <li><Link to="/products">منتجات</Link></li>
                        </ul>
                        <h2>تفاصیل المنتج</h2>
                    </div>
                </div>
            </section>

            <section className="project-details">
                <div className="container">
                    <div className="project-details__content">
                        {product && (
                            <div className="row">
                                <div className="col-xl-8 col-lg-8">
                                    <div className="project-details__content-left">
                                        <h3 className="project-details__title-1">{product.title}</h3>
                                        <p className="project-details__text-1">
                                            <div dangerouslySetInnerHTML={{ __html: product.description }}></div>
                                        </p>
                                    </div>
                                    <div className="project-details__details-box mt-3">
                                        <div className="project-details__bg-shape" style={{ backgroundImage: 'url(../uploads/project-details-shape-1.png)' }}>
                                        </div>
                                        <div className="project-details__social">
                                            {social && social.map((item, index) => (
                                                <a key={index} href={item.social_link}>
                                                    <div dangerouslySetInnerHTML={{ __html: item.social_icon }}></div>
                                                </a>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4">
                                    <div className="project-details__content-right">
                                        <div className="mb-5 py-2">
                                            <img src={`../../${product.image}`} alt={product.title} />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </section>

            <section className="Smilar-project">
                <div className="container">
                    <div className="section-title text-center">
                        <span className="section-title__tagline">ألق نظرة على هذه المنتجات أيضًا</span>
                        <h2 className="section-title__title">منتجاتنا</h2>
                        <div className="section-title__icon">
                            <img src="../../uploads/section-title-icon-1.png" alt="section-title-icon" />
                        </div>
                    </div>
                    <div className="row">
                        {products && products.map((product, index) => (
                            <div className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay={`${index * 100}ms`} key={index}>
                                <div className="project-one__single">
                                    <div className="project-one__inner">
                                        <div className="project-one__img">
                                            <img src={`../../${product.image}`} alt={product.title} />
                                        </div>
                                        <div className="project-one__arrow">
                                            <Link to={`/details/${product.id}`}><i className="icon-right-arrow" /></Link>
                                        </div>
                                        <div className="project-one__content">
                                            <span className="project-one__tagline">نوع</span>
                                            <h3 className="project-one__title"><Link to={`/details/${product.id}`}>{product.title}</Link></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>



        </>
    );
}

export default Details;