import React from 'react';
import { createBrowserRouter } from "react-router-dom";
import Error from './Error';
import App from '../App';
import Home from '../Pages/Home';
import Contact from '../Pages/Contact';
import Products from '../Pages/Products';
import Details from '../Pages/Details';

const Routes = createBrowserRouter([
    {
        path: "/",
        element: <App><Home /></App>,
        errorElement: <Error />,
    },
    {
        path: "/contact-us",
        element: <App><Contact /></App>,
        errorElement: <Error />,
    },
    {
        path: "/products",
        element: <App><Products /></App>,
        errorElement: <Error />,
    },
    {
        path: "/details/:id",
        element: <App><Details /></App>,
        errorElement: <Error />,
    },
]);

export default Routes;