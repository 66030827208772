import { Link } from "react-router-dom";

const Footer = ({ data, social }) => {
    return (
        <>
            <footer className="site-footer">
                <div className="site-footer__top">
                    <div className="container">
                        <div className="site-footer__top-inner">
                            <div className="site-footer-shape-1 float-bob-x" style={{ backgroundImage: 'url(../uploads/site-footer-shape-1.png)' }} />
                            <div className="row">
                                <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="100ms">
                                    <div className="footer-widget__column footer-widget__about">
                                        <div className="footer-widget__logo">
                                            <Link to="/" className="fw-bold h1">
                                                <span className="text-success"> شركة خیرات </span><span className="text-warning"> العراق</span>
                                            </Link>
                                        </div>
                                        <div className="footer-widget__about-text-box">
                                            <p className="footer-widget__about-text">{data.description}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="200ms">
                                    <div className="footer-widget__column footer-widget__Explore">
                                        <div className="footer-widget__title-box">
                                            <h3 className="footer-widget__title">روابط</h3>
                                        </div>
                                        <ul className="footer-widget__Explore-list list-unstyled">
                                            <li className="scrollToLink">
                                                <Link to="/">الرئیسیة</Link>
                                            </li>
                                            <li className="scrollToLink">
                                                <a href="#about">عن شرکة</a>
                                            </li>
                                            <li className="scrollToLink">
                                                <Link to="/contact-us">اتصال بنا</Link>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="300ms">
                                    <div className="footer-widget__column footer-widget__news">
                                        <div className="footer-widget__title-box">
                                            <h3 className="footer-widget__title">_</h3>
                                        </div>
                                        <ul className="footer-widget__Explore-list list-unstyled">
                                            <li className="scrollToLink">
                                                <Link to="/products">منتجات</Link>
                                            </li>

                                            <li className="scrollToLink">
                                                <a href="#why-us">لماذا نحن؟</a>
                                            </li>
                                            <li className="scrollToLink">
                                                <a href="#our-vision">روئيتنا</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="400ms">
                                    <div className="footer-widget__column footer-widget__Contact">
                                        <div className="footer-widget__title-box">
                                            <h3 className="footer-widget__title">معلومات اتصال</h3>
                                        </div>
                                        <ul className="footer-widget__Contact-list list-unstyled">
                                            <li>
                                                <div className="icon">
                                                    <span className="fas fa-phone-square-alt" />
                                                </div>
                                                <div className="text">
                                                    <p><a href={`tel:964${data.phone_no}`}>
                                                        <div dangerouslySetInnerHTML={{ __html: data.phone_no }}></div>
                                                    </a>
                                                    </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="fas fa-envelope" />
                                                </div>
                                                <div className="text">
                                                    <p><a href={`mailto:${data.email}`}>{data.email}</a></p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-pin" />
                                                </div>
                                                <div className="text">
                                                    <p> {data.address}</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="site-footer__bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="site-footer__bottom-inner">
                                    <p className="site-footer__bottom-text">© {new Date().getFullYear()} <a href="/">khayrataliraq.com</a></p>
                                    <div className="site-footer__social">
                                        {social && social.map((item, index) => (
                                            <a key={index} href={item.social_link}>
                                                <div dangerouslySetInnerHTML={{ __html: item.social_icon }}></div>
                                            </a>
                                        ))}
                                    </div>
                                    <div className="site-footer__bottom-scroll">
                                        <a href="/" className="scroll-to-target scroll-to-top" onClick={(e) => {
                                            e.preventDefault();
                                            window.scrollTo({
                                                top: 0,
                                                behavior: 'smooth'
                                            });
                                        }} ><i className="icon-up-arrow" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

        </>
    );
}

export default Footer;