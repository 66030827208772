import { useEffect, useState } from "react";
import Footer from "./Layout/Footer";
import Header from "./Layout/Header";

function App({ children }) {
  const [general, setGeneral] = useState({
    phone_no: "07514584888",
    email: "info@khayrataliraq.com",
    address: "Best Feed IQ, 1234 Street Name, City Name, Country Name",
    description: "Best Feed IQ is a company that provides the best quality animal feed for your animals. We have a variety of animal feeds that are suitable for all types of animals. Our products are made from the best quality ingredients and are guaranteed to provide your animals with the nutrition they need to stay healthy and strong."
  });

  const [social, setSocial] = useState([]);
  useEffect(() => {
    fetch("/api/general").then((res) => res.json()).then((data) => {
      setGeneral(data);
    });
    fetch("/api/social").then((res) => res.json()).then((data) => {
      setSocial(data);
    });
  }, []);

  return (
    <>
      <main className="page-wrapper">
        <Header data={general} social={social} />
        {children}
        <Footer data={general} social={social} />
      </main>
    </>

  );
}

export default App;
