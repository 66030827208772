import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Products = () => {
    const [prodducts, setProducts] = useState([]);
    useEffect(() => {
        fetch('/api/all-products').then(res => res.json()).then(data => setProducts(data));
    }, []);
    return (
        <>
            <section className="page-header">
                <div className="page-header-bg" style={{ backgroundImage: 'url(../uploads/page-header-bg.jpg)' }}>
                </div>
                <div className="container">
                    <div className="page-header__inner">
                        <ul className="thm-breadcrumb list-unstyled">
                            <li><Link to="/">الرئیسیة</Link></li>
                            <li><span>/</span></li>
                            <li>منتجات</li>
                        </ul>
                        <h2>قائمة منتجات</h2>
                    </div>
                </div>
            </section>
            <section className="project-page-two">
                <div className="container">
                    <div className="row">
                        {prodducts.map(product => (
                            <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInLeft" data-wow-delay="100ms">
                                <div className="project-two__single">
                                    <div className="project-two__img">
                                        <img src={`../../${product.image}`} alt={product.title} />
                                        <div className="project-two__content">
                                            <h3 className="project-two__title"><Link to={`/details/${product.id}`}>{product.title}</Link></h3>
                                            <div className="project-two__arrow">
                                                <Link to={`/details/${product.id}`}><span className="icon-right-arrow" /></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

        </>
    );
}

export default Products;